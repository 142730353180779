<template>
	<div class="pageview">
		<div class="pageviewtitle">
			<div class="titletab">套题列表</div>
		</div>
		<div class="buttonview">
			<el-button type="primary" @click="inadd">添加套题</el-button>
		</div>
		<el-table :data="list" style="width: 100%" stripe border>
			<el-table-column type="index" align="center" width="50" />
			<el-table-column prop="setlistName" label="名称" />
			<el-table-column prop="setlistDesc" label="描述" />
			<el-table-column prop="gradeName" label="年级" align="center" />
			<el-table-column align="center" prop="subjectInfo.subjectName" label="学科" />
			<el-table-column label='操作' align="center">
				<template #default="scope">
					<!-- <el-button size="small" type="primary" @click="">修改
					</el-button> -->
					<!-- deletes(scope.row) -->
					<el-button size="small" type="danger" @click="deletelist(scope.row)">删除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="elpagination">
			<el-pagination background layout="prev, pager, next" @current-change="pagechange" :page-count="pages" />
		</div>
	</div>
</template>

<script>
	import {
		setlist,
		setlistdelete
	} from "../../util/api"
	export default {
		data() {
			return {
				pageNum: 1,
				pageSize: 15,
				pages: 1,
				list: null,
				loading: false
			}
		},
		created() {
			this.getlist()
		},
		methods: {
			deletelist(row){
				this.$confirm('是否删除套题“' + row.setlistName + '”？', '提示', {
					confirmButtonText: '是',
					cancelButtonText: '否',
					type: 'error',
				}).then(() => {
					setlistdelete(row.id).then((res)=>{
						this.$message({
							type: 'success',
							message: '删除成功',
						})
						this.getlist()
					})
				})
			},
			inadd(){
				this.$router.push('/home/setlistadd')
			},
			getlist() { //获取列表
				setlist({
					pageNum: this.pageNum,
					pageSize: this.pageSize
				}).then((res) => {
					this.list = res.data.list
					this.pages = res.data.pages
				})
			},
			pagechange(index) {
				this.pageNum = index
				this.getlist()
			}
		}
	}
</script>

<style scoped>

</style>
